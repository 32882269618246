<nb-card>
  <nb-card-body class="flex-column">
    <nb-auth-block class="flex-centered w-75">
      <ngx-intake-header
        title="Password Reset"
        styleClasses="mt-0"></ngx-intake-header>
      <small class="form-text sub-title">Please enter a new password.</small>

      <form (ngSubmit)="resetPass()" #resetPassForm="ngForm">
        <ngx-request-errors
          *ngIf="!submitted"
          [errors]="errors"></ngx-request-errors>
        <div
          *ngIf="messages && messages.length > 0 && !submitted"
          class="alert alert-success"
          role="alert">
          <div>
            <strong>Hooray!</strong>
          </div>
          <div *ngFor="let message of messages">{{ message }}</div>
          <div>You will be redirected in {{redirectDelay/1000}}</div>
        </div>

        <div class="form-group">
          <label for="input-password" class="sr-only">New Password</label>
          <input
            name="password"
            [(ngModel)]="user.password"
            type="password"
            id="input-password"
            class="form-control form-control-lg first"
            placeholder="New Password"
            #password="ngModel"
            [class.form-control-danger]="password.invalid && password.touched"
            [required]="getConfigValue('forms.validation.password.required')"
            [minlength]="getConfigValue('forms.validation.password.minLength')"
            [maxlength]="getConfigValue('forms.validation.password.maxLength')"
            autofocus
            ngxValidatePassword />
          <small
            class="form-text error"
            *ngIf="password.invalid && password.touched && password.errors?.required">
            Password is required!
          </small>
          <small
            class="form-text error"
            *ngIf="(password.invalid && password.touched && (password.errors?.minlength || password.errors?.maxlength))
              || (password.touched && password.errors?.passwordInvalid)">
            Password must contain at lease one uppercase letter, one lowercase
            letter, one number and be at least
            {{getConfigValue('forms.validation.password.minLength')}} characters
            long
          </small>
        </div>

        <div class="form-group">
          <label for="input-re-password" class="sr-only"
            >Confirm Password</label
          >
          <input
            name="rePass"
            [(ngModel)]="user.confirmPassword"
            type="password"
            id="input-re-password"
            class="form-control form-control-lg last"
            placeholder="Confirm Password"
            #rePass="ngModel"
            [class.form-control-danger]="(rePass.invalid || password.value != rePass.value) && rePass.touched"
            [required]="getConfigValue('forms.validation.password.required')"
            [minlength]="getConfigValue('forms.validation.password.minLength')"
            />
          <small
            class="form-text error"
            *ngIf="rePass.invalid && rePass.touched && rePass.errors?.required">
            Password confirmation is required!
          </small>
          <small
            class="form-text error"
            *ngIf="(rePass.touched || (rePass.dirty && !rePass.errors?.minlength)) && password.value != rePass.value && !rePass.errors?.required">
            Password does not match the confirm password.
          </small>
        </div>

        <button
          [disabled]="submitted || !resetPassForm.form.valid || password.value != rePass.value"
          class="btn btn-primary btn-block"
          [class.btn-pulse]="submitted">
          Change password
        </button>
      </form>

      <div class="links col-sm-12 p-3">
        <small class="form-text mr-2">
          Already have an account?
          <a routerLink="../login">
            <strong>Sign In</strong>
          </a>
        </small>
        <small class="form-text">
          <a routerLink="../register">
            <strong>Sign Up</strong>
          </a>
        </small>
      </div>
    </nb-auth-block>
  </nb-card-body>
</nb-card>
